<template>
  <b-overlay
    :show="show"
    rounded="sm"
  >
    <b-card
      title="Edit conversations"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        Edit existing Conversations
      </b-button>
      <b-collapse
        id="collapse-1"
        class="mt-2"
      >
        <div>
          <div>
            <b-form
              ref="form"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >

              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
                :name="item.name"
              >
                <!-- Profession -->
                <b-col
                  md="2"
                >
                  <b-form-group
                    label="Name"
                    label-for="Name"
                  >
                    <!--                    {{ value }}-->
                    <!--                    {{ item.id }}-->
                    {{ item.name }}
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  md="2"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-toggle.collapse-1
                    variant="outline-warning"
                    class="mt-0 mt-md-2"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-25"
                    />
                    <span>Soon</span>
                  </b-button>
                </b-col>
                <b-col
                  md="2"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index,item.name)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col
                  md="2"
                >
                  <b-form-group
                    label="Enable/Disable"
                  >
                    <b-form-checkbox
                      v-model="item.active"
                      class="custom-control-success"
                      name="check-button"
                      switch
                      @change="changeStatus(item.name, item.active , item.uid)"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>

            </b-form>
          </div>
          <!--          <b-button-->
          <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--            variant="primary"-->
          <!--            @click="repeateAgain"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="PlusIcon"-->
          <!--              class="mr-25"-->
          <!--            />-->
          <!--            <span>Add New</span>-->
          <!--          </b-button>-->
        </div>
      </b-collapse>

    </b-card>
    <b-card
      id="conversation-card"
      title="Create conversations"
    >
      <div>
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <draggable
            id="dragdrop"
            v-model="list"
            class="list-group list-group-flush cursor-move"
            tag="ul"
          >
            <transition-group
              type="transition"
              name="flip-list"
            >
              <b-list-group-item
                v-for="(listItem, index) in list"
                :key="listItem.chatterid"
                ref="row"
                tag="li"
              >
                <b-row>
                  <b-col
                    md="2"
                    class="my-auto"
                  >
                    <!--                   Chatters         -->
                    <b-badge
                      v-if="check1(listItem.chatterid)"
                      pill
                      variant="primary"
                      class="my-auto"
                    >
                      {{ listItem.chatterid }}
                    </b-badge>
                    <b-badge
                      v-if="check2(listItem.chatterid)"
                      pill
                      variant="success"
                      class="my-auto"
                    >
                      {{ listItem.chatterid }}
                    </b-badge>
                    <b-badge
                      v-if="check3(listItem.chatterid)"
                      pill
                      variant="danger"
                      class="my-auto"
                    >
                      {{ listItem.chatterid }}
                    </b-badge>
                    <b-badge
                      v-if="check4(listItem.chatterid)"
                      pill
                      variant="secondary"
                      class="my-auto"
                    >
                      {{ listItem.chatterid }}
                    </b-badge>
                    <b-badge
                      v-if="check5(listItem.chatterid)"
                      pill
                      variant="info"
                      class="my-auto"
                    >
                      {{ listItem.chatterid }}
                    </b-badge>
                    <b-badge
                      v-if="checkTimeout(listItem.chatterid)"
                      pill
                      variant="warning"
                      class="my-auto"
                    >
                      {{ listItem.chatterid }}
                    </b-badge>
                    <!--                    Test-->
                  </b-col>
                  <!--                  <b-col md="1">-->
                  <!--                    <b-avatar-->
                  <!--                      variant="primary"-->
                  <!--                      text="1"-->
                  <!--                    />-->
                  <!--                  </b-col>-->
                  <b-col
                    md="8"
                    class="d-flex justify-content-center"
                  >
                    <!--                    <vue-slider-->
                    <!--                      v-if="checkTimeout(listItem.chatterid)"-->
                    <!--                      v-model="time"-->
                    <!--                      :min="0"-->
                    <!--                      :max="max"-->
                    <!--                      :direction="direction"-->
                    <!--                    />-->
                    <b-avatar
                      v-if="checkTimeout(listItem.chatterid)"
                      variant="warning"
                    >
                      <span class="white--text text-h5">{{ listItem.text }}</span>
                    </b-avatar>
                    <b-form-input
                      v-if="!checkTimeout(listItem.chatterid)"
                      id="link2"
                      v-model="listItem.text"
                      type="url"
                      placeholder="Message"
                    />
                    <!--       id="link2"  :id="listItem.id"            </b-card-text>-->
                    <!--                        <small>{{ listItem.chatterid }}</small>-->
                  </b-col>
                  <b-col md="2">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      block
                      @click="removeSlot(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </transition-group>
          </draggable>

          <!-- code  -->

        </b-form>
      </div>
      <b-row class="mt-4">
        <b-col class="col-md-8">
          <b-button-toolbar>
            <b-button-group
              id="grouppp"
              size="my-1"
            >
              <b-button
                id="v-step-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="addMessage(1)"
              >
                +Chatter[1]
              </b-button>
              <b-button
                id="chatter2"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                @click="addMessage(2)"
              >
                +Chatter[2]
              </b-button>
              <b-button
                id="chatter3"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="addMessage(3)"
              >
                +Chatter[3]
              </b-button>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-secondary"
                @click="addMessage(4)"
              >
                +Chatter[4]
              </b-button>
              <b-button
                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                variant="outline-info"
                @click="addMessage(5)"
              >
                +Chatter[5]
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
        <b-col
          md="4"
        >
          <b-button-toolbar class="float-right">
            <b-button-group>
              <b-button
                id="delaybtn"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="print()"
              >
                Tutorial
              </b-button>
              <b-button
                id="randomDelaybtn"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-warning"
                @click="randomDelay()"
              >
                Add random delay
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
      <!--      <b-row class="mt-2">-->
      <!--        <b-col md="6">-->
      <!--          <b-form-input-->
      <!--            id="cName"-->
      <!--            v-model="cName"-->
      <!--            placeholder="Name"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--        <b-col md="6">-->
      <!--          <b-button-->
      <!--            id="save"-->
      <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
      <!--            variant="success"-->
      <!--            block-->
      <!--            @click="test"-->
      <!--          >-->
      <!--            <feather-icon-->
      <!--              icon="SaveIcon"-->
      <!--              class="mr-25"-->
      <!--            />-->
      <!--            <span>Save</span>-->
      <!--          </b-button>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <!---->
      <!--      Old-->
      <b-form
        novalidate
        class="needs-validation"
        @submit.prevent
      >
        <b-form-row class="mt-2">
          <!-- invalid input -->
          <!-- invalid input -->
          <b-col
            md="6"
            class="mb-3"
          >
            <b-form-input
              id="input-invalid2"
              v-model="cName"
              :state="cName.length > 0"
              placeholder="Invalid input"
            />
            <b-form-valid-feedback tooltip>
              Looks good!
            </b-form-valid-feedback>
            <b-form-invalid-feedback tooltip>
              Please provide a valid name.
            </b-form-invalid-feedback>
          </b-col>

          <!-- submit button -->
          <b-col md="6">
            <b-button
              id="save"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              @click="test"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-25"
              />
              <span>Save</span>
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
      <!--      -->
      <app-tour
        name="myTour"
        :steps="steps2"
      />
      <!--    <pre>-->
      <!--    {{ items }}-->

      <!--    </pre>-->
    </b-card>
    <template #overlay>
      <div class="text-center">
        <b-icon
          icon="cash"
          font-scale="3"
          animation="pulse"
        />
        <p id="cancel-label">
          Please buy a license
        </p>
      </div></template>
  </b-overlay>
</template>

<script>
/* eslint-disable vue/no-unused-components,no-unused-vars */
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BBadge,
  BButton,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormRow,
  BListGroupItem,
  BButtonToolbar,
  BButtonGroup,
  BAvatar,
  BCollapse,
  // BFormCheckbox,
  BOverlay, BCard, BIconCash, BIcon, VBToggle, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import VueSlider from 'vue-slider-component'
import store from '@/store/index'
import firebase from 'firebase'
import { getUserData } from '@/auth/utils'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import AppTour from '@core/components/app-tour/AppTour.vue'

export default {
  components: {
    BForm,
    AppTour,
    BRow,
    BCol,
    BCollapse,
    BFormRow,
    BButton,
    BListGroupItem,
    BBadge,
    BFormCheckbox,
    BCard,
    BButtonGroup,
    BIconCash,
    BIcon,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BAvatar,
    BOverlay,
    VueSlider,
    BButtonToolbar,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    draggable,
    Prism,
    // BFormCheckbox,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      time: 15,
      cName: '',
      list: [{
        chatterid: 'Chatter[1]',
        text: '',
        id: '1',
      },
      {
        chatterid: 'Waiting',
        text: '30',
        id: '2',
      }],
      show: false,
      state: false,
      nextTodoId: 3,
      max: 300,
      value1: 30,
      value5: 50,
      items: [],
      dir: 'ltr',
      options: [
        { text: 'VOD', value: '1' },
        { text: 'Clip', value: '2' },
      ],
      steps: [
        // {
        //   target: '#conversation-card',
        //   header: {
        //     title: 'Card',
        //   },
        //   content: 'This card contains demo for the tour.',
        // },
        {
          target: '#delaybtn2',
          header: {
            title: 'Views demo Source',
          },
          content: 'ssssIf you ever find your self confused, you can click on this code icon to check the source of current demo.',
        },
        {
          target: '#grouppp',
          header: {
            title: 'Add another Chatter',
          },
          content: 'If you ever find your self confused, you can click on this code icon to check the source of current demo.',
        },
        {
          target: '#randomDelaybtn',
          header: {
            title: 'View demo Source',
          },
          content: 'If you ever find your self confused, you can click on this code icon to check the source of current demo.',
        },
        {
          target: '#tour-card .btn',
          header: {
            title: 'Trigger Tour',
          },
          content: 'You can click on this button to trigger the tour.',
        },
      ],
      steps2: [
        {
          target: '#chatter2', // We're using document.querySelector() under the hood
          header: {
            title: 'Get Started',
          },
          content: 'Click on <strong>Chatter</strong> to add a Chatter!',
        },
        {
          target: '#randomDelaybtn', // We're using document.querySelector() under the hood
          header: {
            title: 'Get Started',
          },
          content: 'Click on <strong>Add random delay</strong> to add a random delay between your Messages!',
        },
        {
          target: '#link2', // We're using document.querySelector() under the hood
          header: {
            title: 'Get Started',
          },
          content: 'Enter your first Message!',
        },
        {
          target: '#waitingTime', // We're using document.querySelector() under the hood
          header: {
            title: 'Get Started',
          },
          content: 'This is the Time in seconds the next chatter waits before sending the message.',
        },
        {
          target: '#dragdrop',
          header: {
            title: 'Drag&Drop',
          },
          content: 'You can switch the order with simple drag and drop!',
        },
        {
          target: '#link2',
          header: {
            title: 'Mention @',
          },
          content: 'Use   <strong> @{1} </strong>   /    @{number}  to mention other chatters!',
          before: type => new Promise((resolve, reject) => {
            // Time-consuming UI/async operation here
            this.editFirst()
            resolve('foo')
          }),
        },
        {
          target: '#save',
          content: 'Try it, you\'ll love it!<br> Just enter a Name for your conversation and save it!',
          params: {
            placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
      ],
    }
  },
  // watch: {
  //   item() {
  //     console.log(this.items[1])
  //   },
  // },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  mounted() {
    this.initTrHeight()
    // ABFRAGE MAX INCREASE
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.show = true
        }
      })
    sx.collection('User')
      .doc(userData.id)
      .collection('Conversations')
      .get()
      .then(snap => {
        // console.log('SNAPDOC' + snap.get())
        snap.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data())
          // const test = doc.data()
          this.items.push({
            id: this.nextTodoId += 1,
            name: doc.data().name,
            active: doc.data().active,
            uid: doc.id,
          })
        })
      })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    changeStatus(name, activebool, uid) {
      console.log(activebool)
      console.log('Start')
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Conversations')
        .doc(uid)
        .update(
          { active: activebool },
        )
    },
    tour2() {
      this.$tours.myTour.start()
    },
    editFirst() {
      this.list[0].text = 'Hi how are you @{2} ?'
    },
    addMessage(id) {
      this.list.push({
        chatterid: `Chatter[${id.toString()}]`,
        text: '',
        id: this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
      this.nextTodoId += 1
    },
    randomDelay() {
      this.list.push({
        chatterid: 'Waiting',
        text: (Math.floor(Math.random() * 130) + 18).toString(),
        id: this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
      this.nextTodoId += 1
    },
    print() {
      console.log('start')
      this.$tours.vuexyTour.start()
    },
    check1(id) {
      if (id === 'Chatter[1]') {
        return true
      }
      return false
    },
    check2(id) {
      if (id === 'Chatter[2]') {
        return true
      }
      return false
    },
    check3(id) {
      if (id === 'Chatter[3]') {
        return true
      }
      return false
    },
    check4(id) {
      if (id === 'Chatter[4]') {
        return true
      }
      return false
    },
    check5(id) {
      if (id === 'Chatter[5]') {
        return true
      }
      return false
    },
    checkTimeout(id) {
      if (id === 'Waiting') {
        return true
      }
      return false
    },
    // check(item) {
    //   const Link1 = item.Link
    //   if (Link1.includes('twitch.tv')) {
    //     return true
    //   }
    //   return false
    // },
    test() {
      const db = this.$firebase.firestore()
      const userData = getUserData()

      // const { length } = this.items
      // console.log(this.items[length - 1])
      // console.log(element)
      // console.log(element.Link)
      // console.log(element.valueslider)
      // console.log(userData.email)
      // if (element.Link !== '') {
      db.collection('User').doc(userData.id).collection('Conversations')
        .add({
          name: this.cName,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          conversation: this.list,
        })
        .then(() => {
          // console.log('Document successfully updated!')
          this.$swal({
            title: 'Added Conversation',
            text: 'Conversations can now be started via Chatboard!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          // The document probably doesn't exist.
          console.error('Error updating document: ', error)
        })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        options: this.options,
        value: this.items[0].value,
        valueslider: this.items[0].valueslider,
        Link: '',
        views: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeSlot(index) {
      this.list.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removeItem(index, name) {
      this.items.splice(index, 1)
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Conversations')
        .where('name', '==', name)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id)
            db.collection('User')
              .doc(userData.id)
              .collection('Conversations')
              .doc(doc.id)
              .delete()
              .then(() => {
                console.log('Deleted document')
              })
          })
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
@import '@core/scss/vue/libs/tour.scss';
</style>
